(function ($) {
  Drupal.behaviors.descriptionTabs = {
    attach: function () {
      var $wrapper = $('.how-to-use__wrap');
      var $awardsContainer = $('.how-to-use__awards', $wrapper);
      var $awardsContent = $wrapper.find('.how-to-use__awards > .how-to-use__content');
      var $awardsChildren = $awardsContent.children();

      if ($awardsChildren.length === 0) {
        $awardsContainer.hide();
      }

      var buffer = site.client.isMobile ? 96 : 75;

      $('.how-to-use__title', $wrapper).click(function (event) {
        event.preventDefault();

        var $parent = $(this).closest('.outer-wrap');
        var $currentActive = $wrapper.find('.outer-wrap.active');

        if (!$parent.hasClass('active')) {
          $currentActive.removeClass('active');
          $parent.addClass('active');
        } else {
          $parent.toggleClass('active');
        }
        $('html,body').animate({
          scrollTop: $parent.offset().top - buffer
        });
      });
    }
  };
})(jQuery);
